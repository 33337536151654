<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/message/screens' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>影厅列表</el-breadcrumb-item>
      <el-breadcrumb-item>售出详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 头部卡片 -->
    <el-card class="headcard">
      <div slot="header">
        <span>{{ showData.名称 }}</span>
      </div>
      <div class="setstatus">
        <div class="seat-status">
          座位情况 :
          <div>
            <span id="green"></span>
            {{ showData.可用座位数 - showData.已售出座位数 }}
          </div>
          <div>
            <span id="red"></span>
            {{ showData.不可用座位数 }}
          </div>
          <div>
            <span id="grey"></span>
            {{ showData.已售出座位数 }}
          </div>
        </div>
        <div class="total-price">
          <div v-if="selectedSeatList && selectedSeatList.length">
            <span style="padding-right: 30px">合计：</span>
            <!-- <span style="font-size: 14px; text-decoration: line-through;">￥{{ totalMoney }}元</span> -->
            <span style="font-size: 20px"
              >￥{{
                (activeTicketData.price * activeTicketNumNew).toFixed(2)
              }}元</span
            >
          </div>
          <div>
            <el-button
              type="success"
              v-if="selectedSeatList && selectedSeatList.length > 0"
              @click="addDialogOpen()"
              >确认兑票</el-button
            >
          </div>
        </div>
      </div>
    </el-card>
    <!-- 底部卡片 -->
    <el-card>
      <div slot="header">
        <!-- 搜索框 -->
        <div style="font-weight: bold; font-size: 20px; margin-bottom: 10px">
          座位售出情况
        </div>
        <el-row :gutter="24">
          <el-col :span="4">
            演出日期：
            <el-date-picker
              type="date"
              :clearable="false"
              placeholder="请选择日期"
              v-model="selectedDate"
              @change="getSessionList()"
            ></el-date-picker>
            <el-select
              v-model="selectedValue"
              placeholder="请选择场次"
              @change="handleClick"
            >
              <el-option
                v-for="(item, index) in sessionList"
                :key="index"
                :label="item.放映影片.名称 + '：' + item.开始时间"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            出票渠道：
            <el-select
              v-model="qudaoType"
              placeholder="请选择出票渠道类型"
              @change="clickChannel"
              clearable
              @clear="clearQudaoType"
            >
              <el-option
                v-for="(item, index) in qudaoTypeList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-if="qudaoList.length === 0"
              disabled
              placeholder="该渠道类型暂无渠道"
            ></el-input> -->
            <el-select
              filterable
              v-model="qudao"
              placeholder="请选择出票渠道"
              @change="clickChannelUser"
            >
              <el-option
                v-for="(item, index) in qudaoList"
                :key="index"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="16">
            <div class="ticket-title">
              <div>
                <span>{{ qudaoType }}渠道：</span>
                <span>{{ ticket.length }}类票种</span>
              </div>
              <div>
                <div>
                  已出售：{{ showData.已售出座位数 }} / 未出售：
                  {{ showData.可用座位数 - showData.已售出座位数 }} / 不可用：{{
                    showData.不可用座位数
                  }}
                </div>
              </div>
            </div>
            <div class="ticket-item">
              <div v-if="filterTicket.length === 0">该区域暂无票种信息</div>
              <div
                :class="{ activeTicket: item.ID === activeTicketData.ID }"
                @click="selectTicketType(item)"
                v-else
                v-for="(item, index) in filterTicket"
                :key="index"
              >
                <div>价格：{{ item.price }}</div>
                <div>票名：{{ item.areaName + item.name }}</div>
                <div>可选座位：{{ item.seat_number }}</div>
              </div>
            </div>
          </el-col>
        </el-row>

        <div id="tip">
          <div>
            <span style="font-weight: bold">提示:</span> 绿色座位为：可选座位 |
            红色座位为：已损坏座位 | 灰色座位为：预售出/已售出座位
          </div>
          <div>
            <span>票数量：</span>
            <el-input-number
              v-model="activeTicketNumNew"
              @select="handleBlur"
              step-strictly
              @change="handleChange"
              :min="1"
              :max="999"
              label="票数量"
            ></el-input-number>
          </div>
        </div>
        <div id="tip">
          <div>
            <span>快速选择座位：</span>
            <el-input v-model="inputHang" placeholder="请输入行数:1或1-2"></el-input>
          </div>
          <div>
            <el-button type="primary" @click="checkSeat">快速选定</el-button>
          </div>
        </div>
        <hr />

        <!-- 价格信息 -->
        <el-tag v-for="tag in selectedSeatList" :key="tag.ID" type="danger">
          <!-- {{ tag.区域名 + "区" + tag.名称 + "：" + tag.价格信息[0].原价 + "元" }} -->
          {{ tag.区域名 + "区" + tag.名称 }}
        </el-tag>

        <!-- 座位信息 -->
        <div class="site-box">
          <div class="site-box-title">
            <div
              :class="[{ 'select-areaname': item.areaName == areaName }]"
              v-for="(item, i) in areaList"
              :key="i"
              @click="handleClick(item.areaName)"
            >
              {{ item.areaName }}区
            </div>
          </div>
          <div class="site-box-content">
            <div class="seatarea noselectdefult" ref="rowAll">
              <!-- 行号 -->
              <div
                ref="sitebox"
                v-for="(item, i) in seatList"
                class="row"
                :key="i"
              >
                <div class="sitleft">
                  {{ item.rowNum }}
                </div>
                <!-- 座位 -->
                <div
                  ref="sititem"
                  :data-id="item.rowNum + '' + j"
                  :class="[
                    'sit',
                    { 'bg-sited': item1.状态 == 2 },
                    { 'bg-sit': item1.状态 == 1 },
                    { 'bg-nosit': item1.状态 == 9 },
                  ]"
                  v-for="(item1, j) in item.seatList"
                  :key="j"
                  @click.stop.prevent="selectedSeat($event, i, j, item1)"
                >
                  <div v-if="item1['名称']" class="seat-num">
                    {{ item1["名称"] | formatSeat }}
                  </div>
                  <div v-else class="seat-num" style="opacity: 0">
                    {{ 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <!--  <template>
          <el-tabs v-model="areaName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.areaName + '区'"
              :name="item.areaName"
              v-for="(item, i) in areaList"
              :key="i"
            >
              <div class="seatarea" ref="rowAll">
                <div v-for="(item, i) in seatList" class="row" :key="i">
                  <div class="sitleft">
                    {{ item.rowNum }}
                  </div>
                  <div
                    :class="[
                      'sit',
                      { 'bg-sited': item1.状态 == 2 },
                      { 'bg-sit': item1.状态 == 1 },
                      { 'bg-nosit': item1.状态 == 9 },
                    ]"
                    v-for="(item1, j) in item.seatList"
                    :key="j"
                    @click="selectedSeat($event, i, j, item1)"
                  >
                    <div v-if="item1['名称']" class="seat-num">
                      {{ item1["名称"] | formatSeat }}
                    </div>
                    <div v-else class="seat-num" style="opacity: 0">
                      {{ 0 }}
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </template> -->
      </div>
    </el-card>

    <!-- 确认出票 -->
    <el-dialog
      title="补充订单信息"
      :visible.sync="addDialogVisible"
      width="50%"
      @closed="addHandleClose"
    >
      <template>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content">
              <div style="margin-top: 10px">请选择支付类型：</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-select
                v-model="orderfrom"
                placeholder="请选择支付类型"
                @change="selectOrderFrom"
              >
                <el-option
                  v-for="(item, index) in orderFromOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content">
              订单金额：{{
                (activeTicketData.price * activeTicketNumNew).toFixed(2)
              }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              座位数量：{{ selectedSeatList.length }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              影片名称：{{ selectedSession.放映影片.名称 }}
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content">
              渠道类型：{{ activeQudaoType[0].name }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              渠道：{{ qudao ? qudao : activeQudaoType[0].name }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">场次：{{ activeChangci }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content">票单号：{{ activeTicketData.ID }}</div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">票名：{{ activeTicketData.name }}</div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              影片码：{{ selectedSession.放映影片ID }}
            </div>
          </el-col>
        </el-row>
      </template>

      <span slot="footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="lockSeat">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
import AreaSelector from "@/utils/AreaSelector.js";

import { ticketByid, qudaoByid, qudaoTypeList,qudaoList } from "@/network/channel.js";
export default {
  name: "detalied",
  data() {
    return {
      inputHang: "",
      qudaoTypeData: [], //渠道类型数据
      qudaoTypeList: [], //渠道类型过滤
      qudaoList: [], //渠道列表
      ticket: [], //票种信息
      filterTicket: [], //票种过滤
      qudaoType: "", //渠道类型
      qudao: "", //渠道
      activeQudaoType: [{ name: "" }], //选中的渠道类型
      activeChangci: "", //选择的场次时间
      activeTicketData: {}, //选择的票种数据
      // ticketNum: 3,//当前票种的座位数
      tipSelect: "请选择渠道类型",
      activeTicketNum: 1, //票数量
      activeTicketNumNew: 1, //票数量
      activeSeatDom: [], //选的座位的dom

      companyId: window.sessionStorage.getItem("companyId"),
      companyName: window.sessionStorage.getItem("companyName"),
      userId: window.sessionStorage.getItem("userId"),
      id: this.$route.query.id,
      showData: {},
      seatList: [], //座位信息
      oldSeatList: [], //座位信息原数据
      areaList: [],
      sessionList: [],
      areaName: "",
      selectedDate: new Date(),
      selectedValue: "",
      selectAreaName: "",
      selectedSession: {
        放映影片: {
          名称: "",
        },
      },
      // 选中的座位
      selectedSeatList: [],
      totalMoney: 0,
      movieInfo: {},
      orderfrom: "", //支付方式
      orderFromOptions: [
        {
          name: "支付宝",
          type: 4,
        },
        {
          name: "OTA兑换",
          type: 6,
        },
        {
          name: "VM",
          type: 7,
        },
        {
          name: "对公汇款",
          type: 8,
        },
        {
          name: "欠款",
          type: 9,
        },
        {
          name: "微信码",
          type: 3,
        },
        {
          name: "现款",
          type: 2,
        },
      ],
      fromOrderNo: "",
      addDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
      },
      isExceed:false,


      // 框选的元素格式化成对象
      selectDivAll: [],
      // 所有座位对象
      allseatListItem: [],
      // 是否按了ctrl键
      isCtrlDown: false, // 框选的元素格式化成对象
    };
  },
  created() {
    console.log("companyName", this.companyName);
    this.getSessionList();
    this.getChannel();
    this.getAllQudaoList()
    this.isClickCtrlDown();
  },
  mounted() {
    console.log("mounted");
    let that = this
    this.$nextTick(() => {
      const areaSelector = new AreaSelector({
        element: document.querySelector(".seatarea"),
        selectableTargetSelector: ".bg-sit",
        datasetKeyForSelection: "id",
        number:this.activeTicketNumNew,
        onSelectionChange: (selectedIds) => {
          that.selectDivAll = [];
          if (that.isCtrlDown) {
            console.log("selectedIds", selectedIds);
            that.getElementByDataId(selectedIds);
          }
        },
      });
    });
  },
  methods: {
    // 框选函数
    getElementByDataId(targetIdArr) {
      console.log("targetIdArr: ", targetIdArr);

      for (let i = 0; i < this.$refs.sititem.length; i++) {
        targetIdArr.map((item) => {
          if (this.$refs.sititem[i].dataset.id === String(item)) {
            if (
              this.allseatListItem[i].状态 == 1 ||
              this.allseatListItem[i].状态 == 9
            ) {
              this.selectDivAll.push({
                item: this.allseatListItem[i],
                event: this.$refs.sititem[i],
              });
            }
          }
        });
      }
      this.selectDivAll.forEach((item) => {
        this.selectedSeat(item.event, 0, 0, item.item);
      });
    },
    // 是否按下ctrl键
    isClickCtrlDown() {
      let that = this
      that.isCtrlDown = false;
      document.body.addEventListener("keydown", function (event) {
        if (event.key === "Control") {
          // keyCode 17 对应Ctrl键
          that.isCtrlDown = true;
        }
      });
      // 添加keyup事件监听器
      document.body.addEventListener("keyup", function (event) {
        if (event.key === "Control") {
          that.isCtrlDown = false;
          that.selectDivAll = [];
        }
      });
    },
    // 重置渠道类型
    clearQudaoType(){

      window.location.reload()
    },
    // 获取所有渠道
    async getAllQudaoList(){
      let res = await qudaoList()
      console.log('res: ', res);
      if(res.data){
        this.qudaoList = res.data
      }
    },
    changeAreaName(item, i) {
      console.log("item,i: ", item, i);
    },
    checkSeat() {
      this.isExceed = false
      console.log(
        "checkSeat",
        this.inputHang,
        this.activeTicketNumNew,
        this.qudaoType
      );
      let num =
        this.activeTicketData.seat_number * parseInt(this.activeTicketNum) + 1;

      let n = this.inputHang.split("-");
      // 获取所有座位行dom
      let selecEventAll = this.$refs.rowAll[this.selectAreaName];
      if (!this.qudaoType) {
        this.$message.error("请先选择渠道");
        return;
      }
      if (Object.keys(this.activeTicketData).length < 1) {
        this.$message({
          type: "warning",
          message: "请选择票种",
        });
        return;
      }

      if (n[n.length-1] < 0 || n[n.length-1] > selecEventAll.children.length) {
        this.$message.error("请输入正确的行数");
        return;
      }
      if (n.length > 1) {
        let newArr = [];
        for (let y = n[0]; y < parseInt(n[n.length - 1]) + 1; y++) {
          newArr.push(y);
        }
        for (let i = 0; i < newArr.length; i++) {
          newArr[i] = parseInt(newArr[i]) - 1;
          let e = this.seatList[newArr[i]].seatList;
          let event = selecEventAll.children;
          let sit = event[newArr[i]].children;
          for (let j = 0; j < (e.length < num ? e.length : num); j++) {
            if(this.isExceed) return
            if (e[j].状态 == "1") {
              this.selectedSeat(sit[j], n, j, e[j]);
            } else {
              num += 1;
            }
          }
        }
      } else {
        n = parseInt(n) - 1;
        let e = this.seatList[n].seatList;
        let event = selecEventAll.children;
        let sit = event[n].children;
        for (let i = 0; i < (e.length < num ? e.length : num); i++) {
          if(this.isExceed) return
          if (e[i].状态 == "1") {
            this.selectedSeat(sit[i], n, i, e[i]);
          } else {
            num += 1;
          }
        }
      }
      this.$forceUpdate();
    },
    // 选票数量
    handleChange(tag) {
      this.isExceed = false
      let num = tag * parseInt(this.activeTicketData.seat_number);
      if (num < this.selectedSeatList.length) {
        let temp = setTimeout(() => {
          this.activeTicketNumNew = this.activeTicketNum;
          clearTimeout(temp);
        }, 50);

        console.log(
          "this.selectedSeatList.length: ",
          this.selectedSeatList.length
        );
        console.log("this.activeTicketNum: ", this.activeTicketNum);
        console.log("this.activeTicketNumNew: ", this.activeTicketNumNew);

        this.$message({
          type: "error",
          message: "无法修改，请先取消座位!",
        });
        return;
      } else {
        this.activeTicketNum = tag;
        console.log("this.activeTicketNum: ", this.activeTicketNum);
      }
    },
    // 选中的座位恢复
    activeSeatDomRes() {
      console.log('this.activeSeatDom: ', this.activeSeatDom);
      this.activeSeatDom.map((item) => {

        if(item.target && item.target.className){
          item.target.className = "sit bg-sit";
        }else{
          item.className = "sit bg-sit";

        }
      });
    },

    handleBlur() {
      this.handleChange();
    },

    // TODO:选支付类型,传值，不传名字
    selectOrderFrom(tag) {
      console.log("tag: ", tag);
      this.orderfrom = tag;
      /* switch (tag) {
        case '1':
            return '其他三方支付';
        case '2':
            return '现金支付';
        case '3':
            return '微信支付';
        case '4':
            return '支付宝支付';
        case '5':
            return '银联支付';
        case '6':
            return 'OTA支付';
        case '7':
            return 'VM';
        case '8':
            return '对公汇款';
        case '9':
            return '欠款';
        default:
          break;
      } */
    },
    // 选票种
    selectTicketType(item) {
      this.selectedSeatList = [];
      this.activeTicketData = {};
      this.activeSeatDomRes();
      this.activeTicketData = item;
      this.tipSelect = "";
    },
    // 获取渠道类别信息
    async getChannel() {
      let qudao = await qudaoTypeList();
      if (qudao.data && qudao.data.length > 0) {
        this.qudaoTypeData = qudao.data;
      }
      this.qudaoTypeList = this.qudaoTypeData.map((item) => {
        return item.name;
      });
    },
    // 选渠道类型
    async clickChannel(tag) {
      this.qudaoType = tag;
      this.qudao = '';
      this.qudaoList = []
      if (this.qudaoType) {
        this.activeQudaoType = this.qudaoTypeData.filter(
          (item) => item.name == this.qudaoType
        );
        console.log("this.activeQudaoType: ", this.activeQudaoType[0].ID);
        this.tipSelect = "请选择票种";
        this.getTicketList();
        this.getQudaoList();
        this.activeSeatDomRes();

        this.selectedSeatList = [];
        this.activeTicketData = {};
      }
    },
    // 选择渠道
    async clickChannelUser(tag) {
      console.log("tag: ", tag);
      this.qudao = tag.name;
      this.qudaoType = tag.typeName;
      this.activeQudaoType = this.qudaoTypeData.filter((item) =>
         item.name == this.qudaoType
      );
      console.log('this.activeQudaoType: ', this.activeQudaoType);
      this.getTicketList()
    },
    // 获取出票渠道
    async getQudaoList() {
      let res = await qudaoByid({
        id: this.activeQudaoType[0].ID,
      });

      if (res.data && res.data.length > 0) {
        this.qudaoList = res.data;
      }
      console.log("getTicketList==res: ", res);
    },
    // 获取票价
    async getTicketList() {
      this.ticket = [];
      this.filterTicket = [];
      let ticketres = await ticketByid({
        teamId: this.activeQudaoType[0].ID,
      });
      console.log("ticketres: ", ticketres);
      if (ticketres.data && ticketres.data.length > 0) {
        this.ticket = ticketres.data;
        this.filterTicketFn();
      }
    },
    // 筛选票种
    filterTicketFn() {
      console.log("this.areaName: ", this.areaName);
      if (this.areaName) {
        this.filterTicket = this.ticket.filter(
          (item) => item.areaName.charAt(0) == this.areaName.charAt(0)
        );
      }
    },

    // 查找影厅信息
    //查找座位信息
    showInFoById(loading) {
      this.$axios({
        url: `/manager/hallDetail?id=${this.id}&selectedSession=${this.selectedSession.ID}`,
      })
        .then((res) => {
          console.log("showInFoByIdres: ", res);
          this.showData = res.data;
          //座位列表
          this.areaList = res.data.区域;
          this.areaName = this.areaList[0].areaName;
          this.handleClick(this.areaName);
          loading.close();
        })
        .catch((err) => err);
    },
    getSessionList() {
      let date = new DateFormat(this.selectedDate).toString(
        "yyyy-0m-0dT08:00:00"
      );
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios({
        url: `/manager/sessionList?currentPage=1&pageSize=10&status=4&selectedDate=${date}`,
      })
        .then((res) => {
          this.sessionList = res.data;
          //座位列表
          if (this.sessionList && this.sessionList.length > 0) {
            console.log("this.sessionList: ", this.sessionList);
            this.selectedValue = this.sessionList[0].ID;
            this.showInFoById(loading);
          } else {
            loading.close();
            this.$message({
              type: "error",
              message: "暂无票务信息!",
            });
          }
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    getSeatList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(' this.areaName: ',  this.areaName);
      this.allseatListItem = []
      this.$axios({
        url:
          "/show/sessionDetail?场次id=" +
          this.selectedSession.ID +
          "&区域名=" +
          this.areaName,
      })
        .then((res) => {
          this.seatList = res.data.区域[0].座位信息;
          this.oldSeatList = res.data.区域[0].座位信息;
          console.log("seatList: ", this.seatList);
          this.movieInfo = res.data.放映影片;
          console.log("movieInfo", this.movieInfo);
          this.seatList.map((item) => {
            this.allseatListItem.push(...item.seatList);
          });
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    // 切换区域
    handleClick(flag) {
      this.areaName = flag;
      this.filterTicketFn();
      console.log("flag.label: ", flag.label);

      console.log("flag: ", flag);
      this.activeTicketData = {}
      this.seatList = [];
      this.selectedSeatList = [];
      this.totalMoney = 0;
      let that = this;
      this.selectedSession = this.sessionList.filter(
        (s) => s.ID == that.selectedValue
      )[0];
      this.sessionList.map((s, i) => {
        if (s.ID == that.selectedValue) {
          this.selectAreaName = i;
        }
      });
      console.log("this.selectedSession: ", this.selectedSession);

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.getSeatList();
      if (!flag) {
        this.showInFoById(loading);
      }
    },

    //改变座位情况
    selectedSeat(event, i, j, item) {
      console.log("event: ", event);
      console.log("selectedSeat===item: ", item.状态);
      if(!this.qudaoType){
        this.$message({
          type: "warning",
          message: '请选择渠道类型',
        });
        return;
      }
      if (this.isCtrlDown && this.selectDivAll.length == 0) {
        return;
      }
      if (Object.keys(this.activeTicketData).length < 1) {
        this.$message({
          type: "warning",
          message: '请选择票种',
        });
        return;
      }


      if (this.selectedDate.getTime() + 3600 * 1000 * 24 < Date.now()) {
        this.$message({
          type: "warning",
          message: "已结束的演出无法操作!",
        });
        return;
      }
      if (item.状态 == "1") {
        let ticketNumber =
          parseInt(this.activeTicketData.seat_number) *
          parseInt(this.activeTicketNum);

        if (
          this.selectedSeatList.length == ticketNumber ||
          this.selectedSeatList.length > ticketNumber
        ) {
          this.isExceed = true
          this.$message({
            type: "warning",
            message:
              "当前票种一次性仅能选择" +
              ticketNumber +
              "张票!,如需添加座位请先添加票数量！",
          });
          return;
        }
        item.状态 = 9;
        this.activeSeatDom.push(event);
        // 选中的票
        this.handleSelect(item);
        console.log("选中购买", this.selectedSeatList);
      } else if (item.状态 == "9") {
        console.log("item: ", item);
        let activeItem = this.selectedSeatList.filter((activeItem) => {
          return activeItem.ID == item.ID;
        });
        console.log('activeItem======: ', activeItem);
        if (activeItem.length == 0) {
          this.$message({
            type: "warning",
            message: "非可售座位无法操作!",
          });
          return;
        }

        item.状态 = 1;
        // 取消购买
        this.handleClose(item);
        console.log("取消购买", this.selectedSeatList);
      } else {
        this.$message({
          type: "warning",
          message: "非可售座位无法操作!",
        });
        return;
      }
    },

    changeCheckInfo(value) {
      console.log(value);
      if (value == "1") {
        this.checkInfoHolder = "请输入订单号后六位";
      } else if (value == "2") {
        this.checkInfoHolder = "请输入身份证号";
      } else if (value == "3") {
        this.checkInfoHolder = "请输入手机号";
      }
    },
    handleSelect(tag) {
      console.log("tag: ", tag);
      this.selectedSeatList.push(tag);
      console.log("this.selectedSeatList: ", this.selectedSeatList);
      const money = tag.价格信息[0].原价;
      this.totalMoney += parseFloat(money);
    },
    handleClose(tag) {
      for (let i = 0; i < this.selectedSeatList.length; i++) {
        const seat = this.selectedSeatList[i];
        if (seat.ID == tag.ID) {
          this.selectedSeatList.splice(i, 1);
          const money = tag.价格信息[0].原价;
          this.totalMoney -= parseFloat(money);
          return;
        }
      }
    },
    //打开添加对话框
    addDialogOpen() {
      this.activeChangci = this.formatChangciTime(
        this.selectedSession.开始时间
      );
      this.addDialogVisible = true;
    },
    //关闭添加影厅弹框
    addHandleClose() {
      this.orderfrom = "";
      this.fromOrderNo = "";
    },
    // 确认兑票
    lockSeat() {
      if (!this.orderfrom) {
        this.$message({
          type: "error",
          message: "请选择支付类型!",
        });
        return;
      }
      this.activeChangci = this.formatChangciTime(
        this.selectedSession.开始时间
      );

      let that = this;
      that.lockSeatHttp("1");

      that
        .$confirm("请确认已完成线下收款或三方平台票务核销", "确认兑票", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          console.log("用户点击确认");
          that.genrateOrder(that);
        })
        .catch(() => {
          console.log("用户点击取消");

          that.lockSeatHttp("2");
        });
    },
    genrateOrder(that) {
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 影片名
      // 原价
      // 影片id
      // 数量
      // 座位id数组
      // 场次id
      // 区域名
      let seatIds = that.selectedSeatList.map((o) => o.ID);
      let goods = [
        {
          title: that.selectedSession.放映影片.名称,
          money: (
            that.activeTicketData.price * that.activeTicketNumNew
          ).toFixed(2),
          id: that.selectedSession.放映影片ID,
          count: that.selectedSeatList.length,
          sceneryAreaId: that.companyId,
          sceneryAreaName: that.companyName, //景德镇记忆
          orderSeat: {
            sessionId: that.selectedSession.ID,
            areaName: this.areaName,
            seatIds,
          },
        },
      ];

      let data = {
        goods,
        address: "",
        // money: that.totalMoney,
        money: (that.activeTicketData.price * that.activeTicketNumNew).toFixed(
          2
        ),
        name: that.selectedSession.放映影片.名称,
        type: 20,
        sceneryAreaId: that.companyId,
        img: that.movieInfo.封面照片,
        userid: that.userId,
        orderType: that.orderfrom, //that.orderfrom == 'PC端商户' ? '1' : '2',
        paystatus: false,
        useDate: that.selectedDate,
        orderfrom: that.orderfrom,
        fromOrderNo: that.fromOrderNo,
        qudao_type: this.activeQudaoType[0].name, //渠道类型
        qudao: this.qudao ? this.qudao : this.activeQudaoType[0].name, //渠道
        changci: this.activeChangci, //场次
        ticketID: that.activeTicketData.ID, //票id
        ticketName: that.activeTicketData.name, //票名
        是否取票: "1",
      };
      console.log("data: ", data);

      that
        .$axios({
          url: `/order/genrate`,
          method: "POST",
          data,
        })
        .then((res) => {
          console.log(res);
          loading.close();
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "兑票成功!",
            });
            that.addDialogVisible = false;
            that.printTicket(
              this.selectedSeatList,
              res.data.订单号,
              res.data.ID,
              this.activeTicketData.name
            );
            that.handleClick();
          } else {
            that.$message.warning(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);

          loading.close();
        });
    },
    lockSeatHttp(type) {
      let seatIds = "";
      this.selectedSeatList.forEach((o) => {
        seatIds += o.ID + ",";
      });
      let sessionId = this.selectedSession.ID;

      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 锁定座位
      const data = {
        seatIds,
        sessionId,
        type,
      };
      that
        .$axios({
          url: `/show/serverLockSeat`,
          method: "PUT",
          data,
        })
        .then((res) => {
          console.log(res);
          loading.close();
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: `座位${type == "1" ? "锁定" : "解锁"}成功!`,
            });
          } else {
            that.$message.warning(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);

          loading.close();
        });
    },
    printTicket(selectedSeatList, orderNum, orderId, ticketType) {
      console.log(
        "selectedSeatList, orderNum, orderId, ticketType: ",
        selectedSeatList,
        orderNum,
        orderId,
        ticketType
      );
      let that = this;
      let date = this.selectedSession.开始时间.split(" ");
      console.log("开始打印票据", orderNum, date);
      selectedSeatList.forEach((o, index) => {
        o.qrUrl = that.genrateERCodeURL(orderId);
        console.log(
          "that.activeTicketData.price: ",
          that.activeTicketData.price
        );
        selectedSeatList[index].价格信息[0].原价 = that.activeTicketData.price;
        console.log(
          " selectedSeatList[index].价格信息[0].原价: ",
          selectedSeatList[index].价格信息[0].原价
        );
      });
      console.log(
        "selectedSeatList: ",
        selectedSeatList,
        date,
        that.movieInfo.总时长,
        orderNum
      );

      this.$router.push({
        path: "/message/ticket",
        query: {
          printInfo: selectedSeatList,
          date,
          time: this.movieInfo.总时长,
          orderNum,
          ticketType,
        },
      });
    },
    genrateERCodeURL(orderId) {
      return `https://test-pyssp.iat.wang/newminipro?type=check&ID=${orderId}&t=${new Date().getTime()}`;
    },

    // 格式化时间
    formatChangciTime(inputDateTime) {
      const regex = /(\d{4})年(\d{2})月(\d{2})日 (\d{2})点(\d{2})分/;
      const match = inputDateTime.match(regex);
      console.log("match: ", match);
      if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];
        const hours = match[4];
        const minutes = match[5];

        // console.log(`年: ${year}, 月: ${month}, 日: ${day}, 时: ${hours}, 分: ${minutes}`);
        return `${year}/${month}/${day} ${hours}:${minutes}`;
      } else {
        console.log("未找到匹配的日期时间格式");
      }
    },
  },
  filters: {
    formatSeat(str) {
      if (str.length > 0) {
        const num = str.match(/\d+/g)[1];
        return num;
      }
      return str;
    },
  },
};
</script>

<style scoped>
hr {
  margin: 20px 5px;
}

.headcard {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

.setstatus {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.setstatus span {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.seat-status {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-around;
}

.seat-status > div {
  display: flex;
  align-items: center;
}

.total-price {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.total-price > :nth-of-type(1) {
  /* border: 1px solid red; */
  width: 200px;
  text-wrap: nowrap;
  font-weight: 600;
  font-size: 16px;
}

#green {
  background: url("../../assets/img/selected.png") center center no-repeat;
  background-size: 100% 100%;
}

#red {
  background: url("../../assets/img/bought.png") center center no-repeat;
  background-size: 100% 100%;
}

#grey {
  background: url("../../assets/img/unselected.png") center center no-repeat;
  background-size: 100% 100%;
}

.seatarea {
  /* float: left; */
  margin: 20px;
  /* width: 2000px; */
  /* height: 2000px; */
  overflow: auto;
  white-space: nowrap;
  padding: 10px;
}

.sit {
  display: inline-block;
  height: 15px;
  width: 15px;
  min-height: 10px;
  min-width: 10px;
  margin: 2px 2px 2px 2px;
  background-size: cover;
  position: static;
}

.sitleft {
  display: inline-block;
  margin: 4px 4px 4px 4px;
  /* line-height: 20px; */
  position: relative;
  width: 20px;
  /* height: 20px; */
  top: 5px;
  text-align: center;
}

/* 可选座位 */
.bg-sit {
  background: url("../../assets/img/selected.png") center center no-repeat;
  background-size: 100% 100%;
}

.bg-sit:hover {
  cursor: pointer;
}

/* 损坏座位 */
.bg-sited {
  background: url("../../assets/img/bought.png") center center no-repeat;
  background-size: 100% 100%;
}

/* 无座 */
.bg-nosit {
  background: url("../../assets/img/unselected.png") center center no-repeat;
  background-size: 100% 100%;
}

.row {
  /* 清除格式并换行 */
  height: 30px;
  clear: both;
}

#tip {
  color: #8c939d;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20%;
  align-items: center;
}

.el-tag {
  margin-left: 10px;
  margin-top: 10px;
}

.seat-num {
  position: relative;
  top: -14px;
  color: #0e0e0e;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
}

:deep .el-tabs__content {
  overflow: auto;
}

/* 票类 */
.ticket-title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}

.ticket-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  /* border: 1px solid red; */
  justify-content: space-between;
}

.ticket-item > div {
  width: 100%;
  display: flex;
  padding: 5px;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid gainsboro;
}

:deep .el-date-editor--date {
  /* width: 180px; */
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.activeTicket {
  background-color: #629df5;
}


.site-box {
  display: flex;
  flex-direction: column;
}
.site-box-title {
  display: flex;
  margin-left: 10%;
}

.site-box-title > div {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid black;
}

.select-areaname {
  background-color: #48a2ff;
}

.noselectdefult {
  /* 禁止文本选中 */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  /* 禁止拖拽选中（针对图片或链接等） */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-drag: none; /* Safari */
}

</style>
